export default function ({ $config, $daytz, route }) {

  $config.register = {
    hasStarted: $daytz(route?.query?.register_start ?? $config.REGISTER_START, 'isAfter'),
    hasEnded: !$daytz(route?.query?.register_end ?? $config.REGISTER_END, 'isBefore'),
    start: $daytz(route?.query?.register_start ?? $config.REGISTER_START),
    end: $daytz(route?.query?.register_end ?? $config.REGISTER_END)
  }
  $config.register.isEnabled = $config.register.hasStarted && !$config.register.hasEnded

  $config.invoices = {
    hasStarted: $daytz($config.STORE_INVOICE_START, 'isAfter'),
    hasEnded: !$daytz($config.STORE_INVOICE_END, 'isBefore'),
    start: $daytz($config.STORE_INVOICE_START),
    end: $daytz($config.STORE_INVOICE_END),
    startDate: $daytz($config.INVOICE_DATE_START, null, 'YYYY-MM-DD').startOf('day'),
    endDate: $daytz($config.INVOICE_DATE_END, null, 'YYYY-MM-DD').endOf('day')
  }
  $config.invoices.isEnabled = $config.invoices.hasStarted && !$config.invoices.hasEnded
}
