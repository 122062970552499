module.exports = env => ({
  title: env.SITE_TITLE,
  titleTemplate: env.SITE_TITLE,
  htmlAttrs: { style: 'overflow-y: auto;' },
  meta: [
    { charset: 'utf-8' },
    { httpEquiv: 'X-UA-Compatible', content: 'IE=edge' },
    { name: 'viewport', content: 'width=device-width, initial-scale=1' },

    { hid: 'og:url', property: 'og:url', content: env.SITE_URL + env.URL_PREFIX },
    { hid: 'og:site_name', property: 'og:site_name', content: env.SITE_TITLE },
    { hid: 'og:title', property: 'og:title', content: env.SITE_TITLE },
    { hid: 'og:description', property: 'og:description', content: env.META_DESCRIPTION },
    { hid: 'og:image', property: 'og:image', content: env.SITE_URL + env.URL_PREFIX + 'share.jpg' },
    { hid: 'og:image:width', property: 'og:image:width', content: '1200' },
    { hid: 'og:image:height', property: 'og:image:height', content: '630' },

    { hid: 'twitter:card', property: 'twitter:card', content: 'summary_large_image' },
    { hid: 'twitter:site', property: 'twitter:site', content: env.SITE_URL + env.URL_PREFIX },
    { hid: 'twitter:title', property: 'twitter:title', content: env.SITE_TITLE },
    { hid: 'twitter:description', property: 'twitter:description', content: env.META_DESCRIPTION },
    { hid: 'twitter:image', property: 'twitter:image', content: env.SITE_URL + env.URL_PREFIX + 'share.jpg' },

    { hid: 'keywords', name: 'keywords', content: env.META_KEYWORDS },
    { hid: 'description', name: 'description', content: env.META_DESCRIPTION }
  ],
  link: [
    { rel: 'canonical', href: env.SITE_URL + env.URL_PREFIX },
  ],
  noscript: [{ innerHTML: 'This website requires JavaScript.' }]
})
