import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3dfd241e = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _70325354 = () => interopDefault(import('../pages/agegate.vue' /* webpackChunkName: "pages/agegate" */))
const _f95f96b0 = () => interopDefault(import('../pages/cupom.vue' /* webpackChunkName: "pages/cupom" */))
const _2d5571bc = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _10835d2c = () => interopDefault(import('../pages/ganhadores.vue' /* webpackChunkName: "pages/ganhadores" */))
const _0b6be520 = () => interopDefault(import('../pages/perfil.vue' /* webpackChunkName: "pages/perfil" */))
const _75c2d37c = () => interopDefault(import('../pages/regulamentos.vue' /* webpackChunkName: "pages/regulamentos" */))
const _2f10e58c = () => interopDefault(import('../pages/admin/actions/index.vue' /* webpackChunkName: "pages/admin/actions/index" */))
const _3cc220d6 = () => interopDefault(import('../pages/admin/calculation.vue' /* webpackChunkName: "pages/admin/calculation" */))
const _7f3e106f = () => interopDefault(import('../pages/admin/commands/index.vue' /* webpackChunkName: "pages/admin/commands/index" */))
const _53abbfe8 = () => interopDefault(import('../pages/admin/cpf-block/index.vue' /* webpackChunkName: "pages/admin/cpf-block/index" */))
const _771b8a40 = () => interopDefault(import('../pages/admin/dashboard.vue' /* webpackChunkName: "pages/admin/dashboard" */))
const _5f1d758f = () => interopDefault(import('../pages/admin/dispatches/index.vue' /* webpackChunkName: "pages/admin/dispatches/index" */))
const _21d0e517 = () => interopDefault(import('../pages/admin/email-templates/index.vue' /* webpackChunkName: "pages/admin/email-templates/index" */))
const _5fb0d564 = () => interopDefault(import('../pages/admin/exports/index.vue' /* webpackChunkName: "pages/admin/exports/index" */))
const _42f01665 = () => interopDefault(import('../pages/admin/faq/index.vue' /* webpackChunkName: "pages/admin/faq/index" */))
const _5dd2fdcf = () => interopDefault(import('../pages/admin/indexes/index.vue' /* webpackChunkName: "pages/admin/indexes/index" */))
const _3ed5e15e = () => interopDefault(import('../pages/admin/invalid-prizes/index.vue' /* webpackChunkName: "pages/admin/invalid-prizes/index" */))
const _42d5cb4d = () => interopDefault(import('../pages/admin/invoices/index.vue' /* webpackChunkName: "pages/admin/invoices/index" */))
const _a8a9800a = () => interopDefault(import('../pages/admin/ips/index.vue' /* webpackChunkName: "pages/admin/ips/index" */))
const _8803cb5a = () => interopDefault(import('../pages/admin/permissions/index.vue' /* webpackChunkName: "pages/admin/permissions/index" */))
const _8349e770 = () => interopDefault(import('../pages/admin/prizes/index.vue' /* webpackChunkName: "pages/admin/prizes/index" */))
const _e47c77ae = () => interopDefault(import('../pages/admin/registrations/index.vue' /* webpackChunkName: "pages/admin/registrations/index" */))
const _d9f764d0 = () => interopDefault(import('../pages/admin/regulations/index.vue' /* webpackChunkName: "pages/admin/regulations/index" */))
const _04710907 = () => interopDefault(import('../pages/admin/statistics.vue' /* webpackChunkName: "pages/admin/statistics" */))
const _1e4cb6f6 = () => interopDefault(import('../pages/admin/tickets/index.vue' /* webpackChunkName: "pages/admin/tickets/index" */))
const _1b053c1e = () => interopDefault(import('../pages/admin/tickets-check/index.vue' /* webpackChunkName: "pages/admin/tickets-check/index" */))
const _a169bffe = () => interopDefault(import('../pages/admin/tokens/index.vue' /* webpackChunkName: "pages/admin/tokens/index" */))
const _05e51ca6 = () => interopDefault(import('../pages/admin/total-tickets/index.vue' /* webpackChunkName: "pages/admin/total-tickets/index" */))
const _85ef1452 = () => interopDefault(import('../pages/admin/users/index.vue' /* webpackChunkName: "pages/admin/users/index" */))
const _fa24183a = () => interopDefault(import('../pages/admin/winners/index.vue' /* webpackChunkName: "pages/admin/winners/index" */))
const _3b60ad04 = () => interopDefault(import('../pages/admin/winners-draw/index.vue' /* webpackChunkName: "pages/admin/winners-draw/index" */))
const _5bc47776 = () => interopDefault(import('../pages/auth/forgot.vue' /* webpackChunkName: "pages/auth/forgot" */))
const _422ac6e6 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _a35dbf94 = () => interopDefault(import('../pages/auth/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _3fe2dc94 = () => interopDefault(import('../pages/admin/auth/forgot.vue' /* webpackChunkName: "pages/admin/auth/forgot" */))
const _49d736b4 = () => interopDefault(import('../pages/admin/auth/login.vue' /* webpackChunkName: "pages/admin/auth/login" */))
const _3ec0b748 = () => interopDefault(import('../pages/admin/auth/update.vue' /* webpackChunkName: "pages/admin/auth/update" */))
const _a774f1e2 = () => interopDefault(import('../pages/admin/dispatches/create.vue' /* webpackChunkName: "pages/admin/dispatches/create" */))
const _40000887 = () => interopDefault(import('../pages/admin/email-templates/create.vue' /* webpackChunkName: "pages/admin/email-templates/create" */))
const _42c700f9 = () => interopDefault(import('../pages/admin/faq/create.vue' /* webpackChunkName: "pages/admin/faq/create" */))
const _158c9fb4 = () => interopDefault(import('../pages/admin/regulations/create.vue' /* webpackChunkName: "pages/admin/regulations/create" */))
const _61cd2add = () => interopDefault(import('../pages/admin/tokens/create.vue' /* webpackChunkName: "pages/admin/tokens/create" */))
const _0bba8fc7 = () => interopDefault(import('../pages/admin/users/create.vue' /* webpackChunkName: "pages/admin/users/create" */))
const _58693e3a = () => interopDefault(import('../pages/admin/winners-draw/create.vue' /* webpackChunkName: "pages/admin/winners-draw/create" */))
const _cafb4c44 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _54de5c8d = () => interopDefault(import('../pages/admin/auth/reset/_token.vue' /* webpackChunkName: "pages/admin/auth/reset/_token" */))
const _23b55a80 = () => interopDefault(import('../pages/admin/email-templates/edit/_id.vue' /* webpackChunkName: "pages/admin/email-templates/edit/_id" */))
const _dec0031c = () => interopDefault(import('../pages/admin/faq/edit/_id.vue' /* webpackChunkName: "pages/admin/faq/edit/_id" */))
const _7123965f = () => interopDefault(import('../pages/admin/regulations/edit/_id.vue' /* webpackChunkName: "pages/admin/regulations/edit/_id" */))
const _2a29dc80 = () => interopDefault(import('../pages/admin/users/edit/_id.vue' /* webpackChunkName: "pages/admin/users/edit/_id" */))
const _72b0211a = () => interopDefault(import('../pages/admin/winners-draw/edit/_id.vue' /* webpackChunkName: "pages/admin/winners-draw/edit/_id" */))
const _5072daf4 = () => interopDefault(import('../pages/admin/actions/_id.vue' /* webpackChunkName: "pages/admin/actions/_id" */))
const _c14ce092 = () => interopDefault(import('../pages/admin/dispatches/_id.vue' /* webpackChunkName: "pages/admin/dispatches/_id" */))
const _e2eeb974 = () => interopDefault(import('../pages/admin/invalid-prizes/_id.vue' /* webpackChunkName: "pages/admin/invalid-prizes/_id" */))
const _17ef8616 = () => interopDefault(import('../pages/admin/invoices/_id.vue' /* webpackChunkName: "pages/admin/invoices/_id" */))
const _c7cfc1a2 = () => interopDefault(import('../pages/admin/ips/_ip.vue' /* webpackChunkName: "pages/admin/ips/_ip" */))
const _644f80b0 = () => interopDefault(import('../pages/admin/prizes/_id.vue' /* webpackChunkName: "pages/admin/prizes/_id" */))
const _1014bcd1 = () => interopDefault(import('../pages/admin/registrations/_id.vue' /* webpackChunkName: "pages/admin/registrations/_id" */))
const _ee7c6196 = () => interopDefault(import('../pages/admin/validation/_prize.vue' /* webpackChunkName: "pages/admin/validation/_prize" */))
const _1480f24d = () => interopDefault(import('../pages/auth/reset/_token.vue' /* webpackChunkName: "pages/auth/reset/_token" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _3dfd241e,
    name: "admin"
  }, {
    path: "/agegate",
    component: _70325354,
    name: "agegate"
  }, {
    path: "/cupom",
    component: _f95f96b0,
    name: "cupom"
  }, {
    path: "/faq",
    component: _2d5571bc,
    name: "faq"
  }, {
    path: "/ganhadores",
    component: _10835d2c,
    name: "ganhadores"
  }, {
    path: "/perfil",
    component: _0b6be520,
    name: "perfil"
  }, {
    path: "/regulamentos",
    component: _75c2d37c,
    name: "regulamentos"
  }, {
    path: "/admin/actions",
    component: _2f10e58c,
    name: "admin-actions"
  }, {
    path: "/admin/calculation",
    component: _3cc220d6,
    name: "admin-calculation"
  }, {
    path: "/admin/commands",
    component: _7f3e106f,
    name: "admin-commands"
  }, {
    path: "/admin/cpf-block",
    component: _53abbfe8,
    name: "admin-cpf-block"
  }, {
    path: "/admin/dashboard",
    component: _771b8a40,
    name: "admin-dashboard"
  }, {
    path: "/admin/dispatches",
    component: _5f1d758f,
    name: "admin-dispatches"
  }, {
    path: "/admin/email-templates",
    component: _21d0e517,
    name: "admin-email-templates"
  }, {
    path: "/admin/exports",
    component: _5fb0d564,
    name: "admin-exports"
  }, {
    path: "/admin/faq",
    component: _42f01665,
    name: "admin-faq"
  }, {
    path: "/admin/indexes",
    component: _5dd2fdcf,
    name: "admin-indexes"
  }, {
    path: "/admin/invalid-prizes",
    component: _3ed5e15e,
    name: "admin-invalid-prizes"
  }, {
    path: "/admin/invoices",
    component: _42d5cb4d,
    name: "admin-invoices"
  }, {
    path: "/admin/ips",
    component: _a8a9800a,
    name: "admin-ips"
  }, {
    path: "/admin/permissions",
    component: _8803cb5a,
    name: "admin-permissions"
  }, {
    path: "/admin/prizes",
    component: _8349e770,
    name: "admin-prizes"
  }, {
    path: "/admin/registrations",
    component: _e47c77ae,
    name: "admin-registrations"
  }, {
    path: "/admin/regulations",
    component: _d9f764d0,
    name: "admin-regulations"
  }, {
    path: "/admin/statistics",
    component: _04710907,
    name: "admin-statistics"
  }, {
    path: "/admin/tickets",
    component: _1e4cb6f6,
    name: "admin-tickets"
  }, {
    path: "/admin/tickets-check",
    component: _1b053c1e,
    name: "admin-tickets-check"
  }, {
    path: "/admin/tokens",
    component: _a169bffe,
    name: "admin-tokens"
  }, {
    path: "/admin/total-tickets",
    component: _05e51ca6,
    name: "admin-total-tickets"
  }, {
    path: "/admin/users",
    component: _85ef1452,
    name: "admin-users"
  }, {
    path: "/admin/winners",
    component: _fa24183a,
    name: "admin-winners"
  }, {
    path: "/admin/winners-draw",
    component: _3b60ad04,
    name: "admin-winners-draw"
  }, {
    path: "/forgot",
    component: _5bc47776,
    name: "auth-forgot"
  }, {
    path: "/login",
    component: _422ac6e6,
    name: "auth-login"
  }, {
    path: "/register",
    component: _a35dbf94,
    name: "auth-register"
  }, {
    path: "/admin/forgot",
    component: _3fe2dc94,
    name: "admin-auth-forgot"
  }, {
    path: "/admin/login",
    component: _49d736b4,
    name: "admin-auth-login"
  }, {
    path: "/admin/update",
    component: _3ec0b748,
    name: "admin-auth-update"
  }, {
    path: "/admin/dispatches/create",
    component: _a774f1e2,
    name: "admin-dispatches-create"
  }, {
    path: "/admin/email-templates/create",
    component: _40000887,
    name: "admin-email-templates-create"
  }, {
    path: "/admin/faq/create",
    component: _42c700f9,
    name: "admin-faq-create"
  }, {
    path: "/admin/regulations/create",
    component: _158c9fb4,
    name: "admin-regulations-create"
  }, {
    path: "/admin/tokens/create",
    component: _61cd2add,
    name: "admin-tokens-create"
  }, {
    path: "/admin/users/create",
    component: _0bba8fc7,
    name: "admin-users-create"
  }, {
    path: "/admin/winners-draw/create",
    component: _58693e3a,
    name: "admin-winners-draw-create"
  }, {
    path: "/",
    component: _cafb4c44,
    name: "index"
  }, {
    path: "/admin/reset/:token?",
    component: _54de5c8d,
    name: "admin-auth-reset-token"
  }, {
    path: "/admin/email-templates/edit/:id",
    component: _23b55a80,
    name: "admin-email-templates-edit-id"
  }, {
    path: "/admin/faq/edit/:id?",
    component: _dec0031c,
    name: "admin-faq-edit-id"
  }, {
    path: "/admin/regulations/edit/:id?",
    component: _7123965f,
    name: "admin-regulations-edit-id"
  }, {
    path: "/admin/users/edit/:id?",
    component: _2a29dc80,
    name: "admin-users-edit-id"
  }, {
    path: "/admin/winners-draw/edit/:id",
    component: _72b0211a,
    name: "admin-winners-draw-edit-id"
  }, {
    path: "/admin/actions/:id",
    component: _5072daf4,
    name: "admin-actions-id"
  }, {
    path: "/admin/dispatches/:id",
    component: _c14ce092,
    name: "admin-dispatches-id"
  }, {
    path: "/admin/invalid-prizes/:id?",
    component: _e2eeb974,
    name: "admin-invalid-prizes-id"
  }, {
    path: "/admin/invoices/:id",
    component: _17ef8616,
    name: "admin-invoices-id"
  }, {
    path: "/admin/ips/:ip",
    component: _c7cfc1a2,
    name: "admin-ips-ip"
  }, {
    path: "/admin/prizes/:id",
    component: _644f80b0,
    name: "admin-prizes-id"
  }, {
    path: "/admin/registrations/:id",
    component: _1014bcd1,
    name: "admin-registrations-id"
  }, {
    path: "/admin/validation/:prize?",
    component: _ee7c6196,
    name: "admin-validation-prize"
  }, {
    path: "/reset/:token?",
    component: _1480f24d,
    name: "auth-reset-token"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
