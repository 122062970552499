import Cookies from 'js-cookie'

export const state = () => ({
	cpf: false,
	open: false,
	position: false,
	agegate: Cookies.getJSON('agegate') || false,
	redirected: Cookies.getJSON('redirected')
})

export const plugins = [(store) => {
	const savedSettings = JSON.parse(window.localStorage.getItem('settings') || '{}')
	store.commit('settings/SET_SETTINGS', savedSettings)
	store.subscribe((mutation, state) => {
		if(/^settings\//i.test(mutation.type))
			window.localStorage.setItem('settings', JSON.stringify(state.settings))
	})
}]

export const mutations = {
	cpf (state, payload) {
		state.cpf = payload
	},
	open (state, payload) {
		state.open = payload
	},
	position (state, payload) {
		state.position = payload
	},
	agegate (state) {
		state.agegate = true
		Cookies.set('agegate', true, { expires: 7 })
	},
	redirected (state, payload) {
		state.redirected = payload
		if (payload) {
			Cookies.set('redirected', payload)
		} else {
			Cookies.remove('redirected')
		}
	}
}
